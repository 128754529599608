import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'sign-in',
    redirect: '/sign-in',
  },
  {
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Signin/Sign-In.vue'),
	},
	{
		path: '/forgot_password',
		name: 'forgot_password',
		component: () => import('../views/Forgot_password/forgot_password.vue'),
	},
	{
		path: '/reset_password',
		name: 'reset_password',
		component: () => import('../views/Forgot_password/reset_password.vue'),
	},
  {
		path: '/sign-up',
		name: 'sign-up',
		component: () => import('../views/Registration/sign-up.vue'),
	},
  {
		path: '/select-type',
		name: 'select-type',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/supplier/select_type.vue'),
	},
  {
		path: '/declaration-type',
		name: 'declaration',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/supplier/declaration.vue'),
	},
  {
		path: '/business_listing',
		name: 'business_listing',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/supplier/business_listing.vue'),
	},
  {
		path: '/about_you',
		name: 'about_you',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Information/about_you.vue'),
	},
	{
		path: '/office_information',
		name: 'office_information',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Information/office_information.vue'),
	},
	{
		path: '/experience_type',
		name: 'experience_type',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Information/experience_type.vue'),
	},
	{
		path: '/expertise',
		name: 'expertise',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Information/expertise.vue'),
	},
	{
		path: '/profile_photo',
		name: 'profile_photo',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Information/individual_photo.vue'),
	},
	{
		path: '/personal_details',
		name: 'personal_details',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Information/personal_details.vue'),
	},
	{
		path: '/address',
		name: 'address',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Information/address.vue'),
	},
	{
		path: '/indivitual_supplier_agreement',
		name: 'indivitual_supplier_agreement',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Information/indivitual_supplier_agreement.vue'),
	},
	
	{
		path: '/account_welcome',
		name: 'account_welcome',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/welcom.vue'),
	},
	{
		path: '/your_information',
		name: 'your_information',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/more_employee/your_information.vue'),
	},
	{
		path: '/your_photo',
		name: 'your_photo',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/more_employee/your_photo.vue'),
	},
	{
		path: '/business_address',
		name: 'business_address',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/more_employee/business_address.vue'),
	},
	{
		path: '/Experiences',
		name: 'Experiences',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/more_employee/Experiences.vue'),
	},
	{
		path: '/Booking_management',
		name: 'Booking_management',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/more_employee/Private_information/booking_managment.vue'),
	},
	{
		path: '/personal_info',
		name: 'personal_info',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/more_employee/Private_information/personal_info.vue'),
	},
	{
		path: '/supplier_agreement',
		name: 'supplier_agreement',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/more_employee/Private_information/supplier_agreement.vue'),
	},
	{
		path: '/product',
		name: 'product',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/product/list.vue'),
	},
	{
		path: '/product/create',
        name: '/product_create',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/product/create.vue'),
	},
	{
        name: 'productedit',
        path: '/product/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/product/edit.vue'),

	},


	{
		path: '/product_variant',
		name: 'product_variant',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/product_variant/list.vue'),
	},
	{
		path: '/product_variant/create',
        name: '/product_variant_create',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/product_variant/create.vue'),
	},
	{
        name: 'product_variantedit',
        path: '/product_variant/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/product_variant/edit.vue'),

	},




	{
		path: '/booking',
		name: 'Booking',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/Booking/list.vue'),
	},
	{
		path: '/booking/create',
        name: '/Booking_create',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/Booking/create.vue'),
	},
	{
        name: 'Bookingedit',
        path: '/booking/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/Booking/edit.vue'),

	},





	{
		path: '/availability',
		name: 'availability',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/Availability/list.vue'),
	},
	{
		path: '/availability/create',
        name: '/availability_create',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/Availability/create.vue'),
	},
	{
        name: 'availabilityedit',
        path: '/availability/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/Availability/edit.vue'),

	},
	{
        name: 'viewavailability',
        path: '/viewavailability/:id',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Config/after_login/Availability/view.vue'),

	},
	{
        name: 'review_list',
        path: '/review_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/after_login/review/list.vue'),

	},
	{
		name: 'reviewedit',
        path: '/review/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/after_login/review/edit.vue'),

	},

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to,from,next)=>{
	if(to.meta.requiresAuth){
		const token = localStorage.getItem('token');
if(token){
	next()

}else{
	next(
		'/sign-in'
	);
}
	}else{
		next()
	}
})
export default router
